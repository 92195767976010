import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Box } from 'react-bulma-components/dist';
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'
import { getCurrentUser, getCustomProductionSteps, getTwConfig } from '../../utilities/Store';

const ProductionOrders = () => {



    // get production steps - TOGGLE TABS
    // get work for each step and its order - default tabs


    const getInitialSteps = () => {
        let productionSteps = getCustomProductionSteps()

        if (productionSteps.length) {
            productionSteps = productionSteps.sort((a, b) => a.order - b.order)
            return productionSteps

        }

        return []

    }

    const [showTabs, setShowTabs] = useState(true)


    const [steps, setSteps] = useState(getInitialSteps() ?? [])
    const [activeStepId, setActiveStepId] = useState(steps.length ? steps[0].id : null)

    const [works, setWorks] = useState(steps.length ? (steps[0].worksTemplate ? steps[0].worksTemplate : null) : null)
    const [activeWorkId, setActiveWorkId] = useState(steps.length ? (steps[0].worksTemplate ? steps[0].worksTemplate[0].id : null) : null)

    // Display them in tabs with their order Number
    // We will find a way to move them betweeen these tabs


    const changeStep = (st) => {

        let works = st.worksTemplate ?? []

        works = works.sort((a, b) => a.order - b.order)

        setActiveStepId(st.id)
        setWorks(works)
        if (works.length) {
            setActiveWorkId(works[0].id)
        }
    }


    const toggleTabs = () => {
        setShowTabs(!showTabs)
    }

    const changeWork = (wk) => {
        setActiveWorkId(wk.id)
    }

    const stepRow = (st) => {
        return <Tabs.Tab active={activeStepId === st.id} key={st.id} onClick={() => { changeStep(st) }} >{st.name}</Tabs.Tab>
    }


    const workRow = (wk) => {
        return <Tabs.Tab active={activeWorkId === wk.id} key={wk.id} onClick={() => { changeWork(wk) }} >{wk.name}</Tabs.Tab>

    }

    const cu = getCurrentUser()
    const assignedToInfo = {
        "id": cu.objectId,
        "username": cu.username
    }



    return (



        <div>

            <Box className="is-shadowless has-background-transparent">

                {
                    showTabs ?

                        <>
                            <Tabs
                                //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
                                // fullwidth={true}
                                type="toggle"
                                align='centered'
                                size='small'
                                className="justboldTabs has-text marginTop10"
                            >
                                {
                                    steps.map((s) => {
                                        return stepRow(s)
                                    })
                                }
                            </Tabs>


                            <Tabs
                                //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
                                // fullwidth={true}
                                // type="toggle"
                                align='centered'
                                size='small'
                                className="boldTabs has-text-black marginTop10"
                            >
                                {
                                    works.map((w) => {
                                        return workRow(w)
                                    })
                                }
                            </Tabs>
                            <p className='has-text-centered has-text-grey'> Assigned to {assignedToInfo.username} </p>
                        </>


                        : null
                }



            </Box>

            <br />
            <Orders columnAuxSize={8} headerTitle='Orders that are recieved at factory and are in production.' screen={screen.Production_Factory} cp_assignToInfo={assignedToInfo} toggleTabs={toggleTabs} />
        </div>
    )

}

export default ProductionOrders