import {  LocationType } from '../utilities/UserClasses'
import { hasFactory } from '../ClientInfo/clientInfo'




export const saveTwConfig = (conf) => {

    let jsons = JSON.stringify(conf)
    localStorage.setItem('twConfig', jsons)

}
export const getTwConfig = () => {
    const twConf = localStorage.getItem('twConfig');
    return JSON.parse(twConf)
}

export const getCustomProductionSteps = () => {
    let twconfig = getTwConfig()
    if (twconfig.customProductionStepsJSON){
        if (twconfig.customProductionStepsJSON.steps){
            if (twconfig.customProductionStepsJSON.steps.length){
                return twconfig.customProductionStepsJSON.steps
            }
        }
    }

    return null
}

export const getCustomQualityChecklist = () => {
    let twconfig = getTwConfig()
    if (twconfig.customQualityChecklist){
                return twconfig.customQualityChecklist
    }

    return []
}

export const locationFromId = (id) => {

    if (!id){
        return null
    }

    let allLocations = getLocations()
    let matchLoc = allLocations.filter((loc) => {
        return loc.objectId === id
    })

    if (matchLoc != null && matchLoc.length) {
        let firstMatch = matchLoc[0]
        // console.log("SEnding location from id = ",id)
        // console.log(firstMatch)
        // console.log("------- ")

        return firstMatch
    }

    console.log("Could not send location from id = ",id)
}


export const saveLocations = (locs) => {

    let jsons = JSON.stringify(locs)

    // for (let i = 0; i < locs.length; i++) {
    //     const thisLoc = JSON.stringify(locs[i]);
    //     jsons.push(thisLoc)
    // }

    http://localhost:49785/vendors/completeOrders

    localStorage.setItem('locations', jsons)
    // localStorage.setItem('currentUser', user.get('username'))
    // localStorage.setItem('currentUsersName', user.get('name'))

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    // localStorage.setItem('loginTime', new Date())

    // Save Position, Location, isDeleted, imageUrl and Phone as well
}


export const getLocations = () => {
    const locs = localStorage.getItem('locations');


    // for (let i = 0; i < locs.length; i++) {
    //     const thisLoc = JSON.parse(locs[i]);

    //     // let locat = Location.initFromObject(thisLoc)
    //     console.log(thisLoc)
    // }

    // console.log('GOT LOCS FROM STORE')
    // console.log(JSON.parse(locs))
    // const name = localStorage.getItem('currentUsersName');

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    return JSON.parse(locs)
}


export const getAptLocations = () => {
    let allLocs = getLocations()

   let fiteredLocs =  allLocs.filter((thisLoc) => {
        return (thisLoc.isHidden !== true)
    })

    if (!hasFactory) {
        fiteredLocs = allLocs.filter((thisLoc) => {
            return (thisLoc.type !== LocationType.factory)
        })
    }


    let cl = getCurrentLocation()

    if (cl.isFranchise === true){
        return [cl]
        // fiteredLocs = fiteredLocs.filter((thisLoc) => {
        //     return (thisLoc.isFranchise !== true)
        // })
    }


    return fiteredLocs

}




export const savePositions = (positions) => {

    let allPos = []

    positions.map((pos) => {
        let thisPos = pos
        if (thisPos.forLocation){
            console.log("THIS FOR LOCATION IS ")
            console.log(thisPos.forLocation)
            if (thisPos.forLocation.objectId){
                thisPos.forLocationId = thisPos.forLocation.objectId 
            }
            if (thisPos.forLocation.id){
                thisPos.forLocationId = thisPos.forLocation.id 
            }
        }else{
            thisPos.forLocationId = null
        }

        allPos.push(thisPos)
    })

    let jsons = JSON.stringify(allPos)
    localStorage.setItem('positions', jsons)
}

export const getPositions = () => {
    const positions = localStorage.getItem('positions');



    // console.log('GOT Positions FROM STORE')
    let parsedPositions = JSON.parse(positions)
    console.log(JSON.parse(positions))

    console.log("ALL SAVED POSITIONS ARE")
    console.log(positions)

    let allLocs = getLocations()



    parsedPositions.map((thisPos) => {
        if (thisPos.forLocationId !== null){

            let matchingLoc = allLocs.filter( (thisLoc) => {
                return thisLoc.objectId === thisPos.forLocationId
            })

            if (matchingLoc){
                if (matchingLoc.length){
                    thisPos.forLocation = matchingLoc[0]
                }
            }

        }
    })


    return parsedPositions
}


export const getAptPositions = () => {

    let allPos = getPositions()

    console.log("ALL POSITIONS")
    console.log(allPos)


    let aptPositions = allPos.filter( (thisPos) => {
        return (
            thisPos.isAdminPosition !== true 
            && thisPos.isAgentPosition !== true 
            && thisPos.isFranchiseAdminPosition !== true 
            && thisPos.isDeletedUserPosition !== true
            )
    })
    
    console.log("APT POSITIONS")
    console.log(aptPositions)

    let cu = getCurrentUser()
    let currentLoc = getCurrentLocation() // NOT IMPLEMENTED
    
    if (cu.isFranchiseAdmin ===  true) {
        // show positions only for this location
        console.log("Filetering for Franchise Admin location")
        aptPositions = aptPositions.filter( (thisPos) => { 
            if (thisPos.forLocationId || thisPos.forLocation) {
                if (thisPos.forLocation){
                    return   (thisPos.forLocation.objectId === currentLoc.objectId) 
                }
                return   (thisPos.forLocationId === currentLoc.objectId) 
            }
         }
        )

        console.log("SENDING APT POSITIONS")
        console.log(aptPositions)


        // aptPositions = aptPositions.filter({ $0.forLocation?.objectId == currentLocation?.objectId })
        
    }

    return aptPositions
}





export const saveUsers = (users) => {

    // users.map( (thisUser) => {
    //    delete thisUser["password"]
    // })

    let jsons = JSON.stringify(users)
    localStorage.setItem('users', jsons)
}


export const getUsers = () => {
    const users = localStorage.getItem('users');

    // console.log('GOT Users FROM STORE')
    // console.log(JSON.parse(users))

    return JSON.parse(users)
}


export const saveCurrentLocation = (location) => {
    localStorage.setItem('currentLocation', JSON.stringify(location))
}

export const getCurrentLocation = () => {
    let loc = localStorage.getItem('currentLocation');

    if (loc != null) {
        loc = JSON.parse(loc)
    } else {
        return null
    }
    return loc
}


export const saveCurrentUser = (user) => {

    delete user["password"]

    localStorage.setItem('userAuthenticated', true)
    localStorage.setItem('currentUser', JSON.stringify(user))

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    localStorage.setItem('loginTime', new Date())

    // Save Position, Location, isDeleted, imageUrl and Phone as well
}


export const getCurrentUser = () => {
    let userObj = localStorage.getItem('currentUser');
    if (userObj == null){
        return null
    }
    let user = JSON.parse(userObj)
    return user 
}

export const getCurrentUserInfo = () => {
    let userObj = localStorage.getItem('currentUser');

    if (userObj == null){
        return null
    }

    let user = JSON.parse(userObj)

    if (user == null){
        return null
    }


    const loginTimeSTR = localStorage.getItem('loginTime')
    const loginTime = new Date(loginTimeSTR)
    let difference_In_Time = new Date().getTime() - loginTime.getTime();
    let difference_In_Hours = difference_In_Time / (1000 * 3600 );
    let difference_In_Minutes = difference_In_Time / (1000 * 60 );

    let locationStr = ''
    let positionStr = user.position.name  ??  ''
    let loginSinceStr = ''

   let thisLoc = getCurrentLocation()

    if (difference_In_Hours > 1) {
        loginSinceStr = 'Logged in since ' + difference_In_Hours.toFixed(2) + ' hrs'
    }else{
        loginSinceStr = 'Logged in since ' + difference_In_Minutes.toFixed(2) + ' mins'
    }

    user.posAtLocInfo = positionStr + ' at ' + (thisLoc.name ?? "") 
    user.loginInfo = loginSinceStr

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    return user 
}


export const removeCurrentUser = (user) => {
    localStorage.setItem('userAuthenticated', false)
    localStorage.setItem('currentUser', null)
    localStorage.setItem('loginTime', null)
}