import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns, Tag, Table, Dropdown } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import { StyleSelection } from '../../utilities/Classes'
import ProductSelectionModal from './productSelectionModal'
// import { picType } from '../../utilities/enums'

import cogoToast from 'cogo-toast';
import ChoiceModal from '../../components/Cards/ChoiceModal';
import ProductionStepWorkModal from './productionStepWorkModal';
import { CustomProductionStep, CustomProductionStepWork } from '../../utilities/UserClasses';
import { createNew_QualityPoint_WithId, create_CustomProductionStep_Id } from '../../utilities/helper';

const CustQualityModal = (props) => {


    let [qPoint, setQPoint] = useState(props.point ?? createNew_QualityPoint_WithId())

    let [saving, setSaving] = useState(false)
    let [showChoiceModal, setShowChoiceModal] = useState(false)


    const closeModals = () => {
        setShowChoiceModal(false)
    }

    const closed = () => {
        props.closed()
    }



    const saveSelection = () => {

        if (qPoint.name == null || qPoint.name === '') {
            cogoToast.warn('Point must have a name')
            return
        }

        // if (step.applicableOn == null) {
        //     cogoToast.warn('Selection must be for atleast 1 product type')
        //     return
        // }
        // if (step.applicableOn.length === 0) {
        //     cogoToast.warn('Selection must be for atleast 1 product type')
        //     return
        // }

        // if (step.options == null) {
        //     cogoToast.warn('Selection must have atleast 1 option')
        //     return
        // }
        // if (step.options.length === 0) {
        //     cogoToast.warn('Selection must have atleast 1 option')
        //     return
        // }
        

        // setSaving(true)

        if (props.addingQualityPoint){
            props.pointIsAdded(qPoint)
        }else{
            props.pointIsEdited(qPoint)
        }

        // if (props.isNewSelection) {

        //     let newSS = StyleSelection.copyFrom(styleSelection)
        //     newSS.add((succ, errMsg) => {
        //         setSaving(false)
        //         if (succ) {
        //             // cogoToast.success('Selection Added')
        //             props.selectionAdded(newSS)
        //         } else {
        //             console.log('Error while adding selection : ' + errMsg)
        //             cogoToast.error('Could not add selection. An error occured.')
        //         }

        //     })


        // } else {

        //     let copySS = StyleSelection.copyFrom(styleSelection)
        //     copySS.update((succ, errMsg) => {
        //         setSaving(false)
        //         if (succ) {
        //             // cogoToast.success('Selection Added')
        //             props.selectionSaved(copySS, props.styleSelectionIndex)
        //         } else {
        //             console.log('Error while updating selection : ' + errMsg)
        //             cogoToast.error('Could not update selection. An error occured.')
        //         }
        //     })

        // }
    }



    const nameIsChanged = (e) => {
        console.log('Name is changed to : ' + e.target.value)
        let o = { ...qPoint }
        o.name = e.target.value
        setQPoint(o)
    }


    const forProductsChanged = (e) => {
        console.log('FOR PRODUCTS CHANGED : ' + e.target.value)
        let val = e.target.value
        let o = { ...qPoint }
        let thisVal = val.split('/r/n')
        o.applicableOn = thisVal
        setQPoint(o)
    }

    const optionsChanged = (e) => {
        console.log('Options CHANGED : ' + e.target.value)
        let val = e.target.value
        let o = { ...qPoint }
        let thisVal = val.replace(/\n/g, "").split(",")

        let fVal = thisVal.filter((opt) => {
            return opt !== "" && opt !== " " && opt !== ","
        })

        o.options = fVal
        setQPoint(o)
    }

 



    const optionsClicked = () => {
        console.log('Options CLICKED')
    }

    // const phoneIsChanged = (e) => {
    //     console.log('Phone is changed to : ' + e.target.value)
    // }

    // const usernameIsChanged = (e) => {
    //     console.log('Username is changed to : ' + e.target.value)
    //     let o = { ...styleSelection }
    //     let appOn = [...o.applicableOn]
    //     o.applicableOn = e.target.app
    //     setUser(o)
    // }

    // const passwordIsChanged = (e) => {
    //     console.log('Password is changed to : ' + e.target.value)
    //     let o = { ...user }
    //     o.password = e.target.value
    //     setUser(o)
    // }

    // const workAdded = (wk) => {
    //     let st = {...step}

    //     let wrks = [...st.worksTemplate ?? []]

    //     if (!wk.order){
    //         wk.order = wrks.length + 1
    //     }

    //     // console.log("NEWLY ADDED STEP IS ", st )
        
    //     wrks.push(wk)
    //     wrks.sort((a, b) => a.order - b.order);
    //     st.worksTemplate = wrks
    //     setStep(st)
    //     closeModals()
    // }

    // const workEdited = (work) => {

    //     let st = {...step}

    //     let wrks = [...st.worksTemplate ?? []]


    //     const index = wrks.findIndex((w) => {
    //         return w.id === work.id
    //     })

    //     if (index != null) {
    //         console.log("THIS INDEX", index)
    //         console.log("At this index", wrks[index])

    //         let thisWork = CustomProductionStepWork.copyFrom(work) 
    //         // console.log("Copied Work", thisWork)

    //         wrks[index] = thisWork
    //         st.worksTemplate = wrks
    //         setStep(st)
    //         closeModals()

    //     }
    // }

    // const workDeleted = (work) => {


    //     let st = {...step}

    //     let wrks = [...st.worksTemplate ?? []]

    //     wrks = wrks.filter((w) => w.id !== work.id)


    //     st.worksTemplate = wrks
    //     setStep(st)
    //     closeModals()



    // }



    // const workRow = (wk) => {
    //     return <tr key={wk.id} onClick={() => { setSelectedWork(wk) }} >
    //         <th className='is-narrow'>
    //             {wk.order}
    //         </th>
    //         <th>
    //             {wk.name}
    //         </th>
    //     </tr>
    // }



    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />



                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Name:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='name' defaultValue={qPoint.name ?? ''} placeholder='Name this Step' type='input' className='input field has-text-weight-semibold type65' onChange={e => { nameIsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>


                {/* <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">FOR PRODUCTS:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <Button className=" is-fullwidth is-small has-background-grey-lighter has-text-grey has-text-weight-bold" onClick={editForProductsPressed}> <FontAwesomeIcon className='' icon={faEdit} /> </Button>
                            <div className='field '>
                                <textarea disabled name='username' defaultValue={styleSelection.applicableOn ? styleSelection.applicableOn.join(', ') : null} placeholder='Selection is applicable on which products' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { forProductsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns> */}

                {/* <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">OPTIONS:</label>
                        <p className='type45'>Seperate entries by comma and new line (, )</p>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <textarea name='username' defaultValue={styleSelection.options ? styleSelection.options.join(',\n') : null} placeholder='All the options of Selection' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { optionsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns> */}






                {/* <Columns className='is-mobile my-4 pb-6'>
                    <Columns.Column>
                    <br />
                    <Heading size={6} subtitle={true} className="mb-1 " > Template of Work for this step </Heading>

                        <Table className='is-hoverable is-size-7'>
                            <thead>
                                <tr>
                                <th className='is-narrow'>
                                        Order
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { worksOfThisStep().map((w) => {
                                    return workRow(w)
                                })}
                            </tbody>

                        </Table>
                        <Button.Group className="is-pulled-right mb-0">
                            <Button className=" is-small is-text" onClick={() => { }}> Change Work Order </Button>
                            <Button className=" is-small has-background-grey-lighter " onClick={addWork}> + Add Work </Button>
                        </Button.Group>
                        <br />
                    </Columns.Column> */}

                    <br />
                    <br />
                    <br />
                    <Button disabled={saving} loading={saving} className='is-fullwidth is-black ' onClick={saveSelection} > Done </Button>


                    <br />
                    <Box className=" is-radiusless is-shadowless has-background-transparent"></Box>

            </div>
        )

    }

    const deleteThisSelection = () => {

        if (props.pointDeleted){
            props.stepDeleted(qPoint)
        }

        // let ss = StyleSelection.copyFrom(step)
        // setSaving(true)
        // ss.delete((succ, errMsg) => {
        //     if (succ) {
        //         props.selectionDeleted(ss, props.styleSelectionIndex)
        //     } else {
        //         console.log("Could not delete StyleSelection. Error = ", errMsg)
        //         cogoToast.error("Could not delete StyleSelection. Check internet and try again.")
        //     }
        // })
    }


    const deletePressed = () => {
        setShowChoiceModal(true)
    }


    const dropdownValChanged = (val) => {
        if (val === "delete") {
            deletePressed()
        }
    }



    const modal = () => {

        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => closed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    <Dropdown className=" is-small is-arrowless is-pulled-left has-background-white noDropdownIcon" color="light" label='•••' onChange={(val) => { dropdownValChanged(val) }} >
                        <Dropdown.Item value="delete">
                            Delete
                        </Dropdown.Item>
                    </Dropdown>
                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.addingStep ? 'New Production Step' : 'Production Step Details'}  </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    const deleteChoices = [
        {
            key: 'delete',
            title: 'Are you sure you want to delete this step. It will not show in any associated products. This cannot be undone.',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Yes. Delete It.',
            highlight: false
        },
        {
            key: 'cancel',
            title: '',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Go Back',
            highlight: false
        },
    ]



    const choiceMade = (choice) => {
        console.log('CHOICE MADE IS')
        console.log(choice)
        closeModals()

        switch (choice.key) {
            case 'delete':
                deleteThisSelection()
                break;
            case 'cancel':
                break;
            default: break;

        }
    }




    return (
        <div>
            {/* {showWorkModal || selectedWork ? <ProductionStepWorkModal addingWork={addingWork} work={selectedWork} workAdded={workAdded} workEdited={workEdited} workDeleted={workDeleted} closed={closeModals} /> : null} */}
            {showChoiceModal ? <ChoiceModal choices={deleteChoices} choiceMade={choiceMade} closed={closeModals} /> : null}
            {modal()}

        </div>

    )

}

export default CustQualityModal