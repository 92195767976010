import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Columns, Button, Tag, Image, Modal, Heading, Card, Label, InputFile, Dropdown } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab, Posture, imageUrlForPostures, MeasurementType, picType } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import LoadingAux from './LoadingAux';
import { Customer, CustomerPic, ProductPic } from '../utilities/Classes';
import cogoToast from 'cogo-toast';
import { layer } from '@fortawesome/fontawesome-svg-core';
import ClientMeasurementsInfo from './ClientMeasurementsInfo';
import ImageViewer from './ImageViewer';
import MeasurementModal from './MeasurementModal';
import ImageUploader from './ImageUploader';
import MeasurementTemplates from './MeasurementTemplates';
import PostureModal from './PostureModal';
import PatternModal from './PatternModal';


const ClientInfoModal = (props) => {

    // assets
    let [customer, setCustomer] = useState(props.customer)
    let [images, setImages] = useState(props.customer.pics ?? [])
    let [measurements, setMeasurements] = useState(props.customer.measurements ?? [])
    let [postures, setPostures] = useState(props.customer.selectedPostures ?? [])
    let [patterns, setPatterns] = useState(props.customer.customMadePatterns ?? [])

    // fetch
    let [imagesFetchNeeded, setImagesFetchNeeded] = useState(true)
    let [measuresFetchNeeded, setMeasuresFetchNeeded] = useState(true)

    // add
    let [addImage, setAddImage] = useState(false)
    let [addMeasurement, setAddMeasurement] = useState(false)
    let [editPostures, setEditPostures] = useState(false)
    let [addPattern, setAddPattern] = useState(false)

    // select
    let [selectedImage, setSelectedImage] = useState(null)
    let [editPatternAtIndex, setEditPatternAtIndex] = useState(null)
    let [measurementSelected, setMeasurementSelected] = useState(null)
    let [useMeasurementFromTemp, setUseMeasurementFromTemp] = useState(null)


    let [isUpdatingCust, setIsUpdatingCust] = useState(false)



    const modal = () => {

        // if (props.customer){
        console.log("_________Incoming Customer in ClientInfoModal is________")
        console.log(props.customer)
        // }


        const addImagePressed = () => {
            setAddImage(true)
        }

        const addMeasurementPressed = () => {
            setAddMeasurement(true)
        }

        const editPosturePressed = () => {
            setEditPostures(true)
        }

        const addPatternPressed = () => {
            setAddPattern(true)
        }

        const editPatternPressed = (editPatternWithIndex) => {
            // expected = {pattern:PATTERN, index: INDEX}
            setEditPatternAtIndex(editPatternWithIndex)
        }



        const imageAdded = (customerPic, isArray) => {
            if (addImage) {
                let ci = [...images] ?? []

                if (isArray || customerPic.length){
                    ci.push.apply(ci, customerPic)
                }else{
                    ci.push(customerPic)
                }

                setImages(ci)
                setAddImage(false)
                // let cust = Customer.copyFrom(customer)
                // cust.pics = ci
                // setCustomer(cust)
            } 
        }

        const imageSelected = (imgObj) => {
            setSelectedImage(imgObj)
        }

        const imageDeleted = (imgObj) => {
            // Find this image and remove
            let ppCopy = [...images]
            let fileteredPP = ppCopy.filter((thisPP) => {
                return thisPP.url !== imgObj.url
            })
    
            // setImages(fileteredPP)
            // let cust = Customer.copyFrom(customer)
            // cust.pics = fileteredPP
            // setCustomer(cust)
        }
    

        const measurementIsSelected = (mtObj) => {
            setMeasurementSelected(mtObj)
        }



        const setCustMeasurements = (mts) => {
            setMeasuresFetchNeeded(false)
            if (measurements.length !== mts) {
                setMeasurements(mts)

                let cust = Customer.copyFrom(customer)
                cust.measurements = mts
                setCustomer(cust)
            }
        }

        const custMeasurmentUpdated = (mObj) => {
            let sn = [...measurements]
            const index = sn.findIndex((mst) => {
                return mst.objectId === mObj.objectId
            })
            if (index != null) {
                sn[index] = mObj
            }
            setMeasurements(sn)
            setNoSelected()

            // let cust = Customer.copyFrom(customer)
            // cust.measurements = sn
            // setCustomer(cust)
        }

        const measurementRemoved = (mObj) => {
            let sn = [...measurements]
            let newCMs = sn.filter((mst) => {
                return mst.objectId !== mObj.objectId
            })
            setMeasurements(newCMs)
            setNoSelected()

            // let cust = Customer.copyFrom(customer)
            // cust.measurements = newCMs ?? []
            // setCustomer(cust)
        }
    
        const custMeasurementAdded = (mObj) => {
            let sn = [...measurements]
            sn.push(mObj)
            setMeasurements(sn)
            setNoSelected()

            // let cust = Customer.copyFrom(customer)
            // cust.measurements = sn
            // setCustomer(cust)
        }

        const setCustImages = (images) => {
            setImagesFetchNeeded(false)
            if (images.length !== images) {
                setImages(images)

                // let cust = Customer.copyFrom(customer)
                // cust.pics = images
                // setCustomer(cust)
            }
        }
    
        const measurementTemplateSelected = (tmp) => {
            tmp.customer = customer
            setNoSelected()
            setUseMeasurementFromTemp(tmp)
        }
    
        const posturesChanged = (postures) => {

            let cust = Customer.copyFrom(customer)
            cust.selectedPostures = postures
    
            cust.update((succ, errMsg) => {
                if (succ) {
                    cogoToast.success("Client Postures updated")
                    setCustomer(cust)
                    setPostures(postures)
                } else {
                    cogoToast.error("Some error occured while updating postures")
                }
                setEditPostures(false)
            })
    
        }
    
        const patternAdded = (pattern) => {
            let pttr = [...patterns ?? []]
            pttr.push(pattern)

            console.log(" >>>>>> ADDING PATTERN ARRAY")
            console.log(pttr)

            let cust = Customer.copyFrom(customer)
            cust.customMadePatterns = pttr
    
            cust.update((succ, errMsg) => {
                if (succ) {
                    cogoToast.success("Pattern Added")

                    console.log(" >>>>>> AFTER UPDATE PATTERNS ARE")
                    console.log(cust)

                    setCustomer(cust)
                    setPatterns(pttr)
                } else {
                    cogoToast.error("Some error occured while adding pattern")
                }
                setAddPattern(false)
            })
        }
    
        const patternChanged = (pattern, isDeleted) => {
            let pttr = [...patterns ?? []]
            if (editPatternAtIndex) {
                let index = editPatternAtIndex.index ?? null;
                if (index !== null) {
                    if (isDeleted === true) {
                        // delete at index 
                        console.log("Pattern delete index is = ", isDeleted)
                        pttr.splice(index, 1);
                        console.log("After Splice is ")
                        console.log(pttr)
    
                    } else {
                        pttr[index] = pattern
                    }
                }
    
                let cust = Customer.copyFrom(customer)
                cust.customMadePatterns = pttr
                cust.update((succ, errMsg) => {
                    if (succ) {
                        cogoToast.success("Pattern Updated")
                        setCustomer(cust)
                        setPatterns(pttr)
                    } else {
                        cogoToast.error("Some error occured while updating pattern")
                    }
                    setEditPatternAtIndex(null)
                })
            }
        }
    

        const setNoSelected = () => {
            setAddImage(false)
            setAddMeasurement(false)
            setEditPostures(false)
            setAddPattern(false)
            setSelectedImage(null)
            setMeasurementSelected(null)
            setUseMeasurementFromTemp(null)
            setEditPatternAtIndex(null)

        }
    

        const hwsUpdated = (hwsObject) => {
            let custCopy = Customer.copyFrom(customer)
            custCopy.height = hwsObject.height
            custCopy.heightUnit = hwsObject.heightUnit
            custCopy.weight = hwsObject.weight
            custCopy.weightUnit = hwsObject.weightUnit
            custCopy.shoeSize = hwsObject.shoeSize
            custCopy.shoeSizeUnit = hwsObject.shoeSizeUnit

            setIsUpdatingCust(true)
            custCopy.update((succ, errMsg) => {
                setIsUpdatingCust(false)
                if (succ){
                    cogoToast.success("Height, Weight, ShoeSize are updated.")
                }else{
                    console.log("Error while trying to update HWS. Error = ", errMsg)
                    cogoToast.error("Could not update HWS. Check internet and try again.")
                }
            })

        }


        const noteUpdate = (note) => {
            let custCopy = Customer.copyFrom(customer)
            custCopy.measurementNote = note ?? ""
    
            setIsUpdatingCust(true)
            custCopy.update((succ, errMsg) => {
                setIsUpdatingCust(false)
                if (succ) {
                    cogoToast.success("Measurement Note updated.")
                    if (props.customer) {
                        props.customerUpdated(custCopy)
                    } else {
                        setCustomer(custCopy)
                    }
                } else {
                    console.log("Error while trying to update HWS. Error = ", errMsg)
                    cogoToast.error("Could not update Measurement Note. Check internet and try again.")
                }
            })
    
        }


        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed(customer)} >
                <Modal.Content className='is-padingless has-background-white'>

                
                    {(selectedImage !== null) ? <ImageViewer imgObj={selectedImage} type='customerPic' imageDeleted={imageDeleted} closed={setNoSelected} /> : null}
                    {(measurementSelected !== null) ? <MeasurementModal purpose='edit' measurement={measurementSelected} type={MeasurementType.body} closed={setNoSelected} measurementUpadted={custMeasurmentUpdated} measurementRemoved={measurementRemoved} /> : null}
                    {(useMeasurementFromTemp !== null) ? <MeasurementModal purpose='useTemplate' measurement={useMeasurementFromTemp} type={MeasurementType.body} closed={setNoSelected} templateUsed={custMeasurementAdded} /> : null}

                    {(addImage === true) ? <ImageUploader customer={customer} product={null} type={picType.CustomerPic} closed={setNoSelected} imageAdded={imageAdded} /> : null}
                    {(addMeasurement === true) ? <MeasurementTemplates type={MeasurementType.body} closed={setNoSelected} selectedTemplate={measurementTemplateSelected} isToSelectTemplate={true} /> : null}
                    {(editPostures === true) ? <PostureModal selectedPostures={postures} closed={setNoSelected} posturesChanged={posturesChanged} /> : null}

                    {(addPattern === true) ? <PatternModal exitingPattern={null} closed={setNoSelected} patternAdded={patternAdded} /> : null}
                    {editPatternAtIndex ? <PatternModal existingPattern={editPatternAtIndex} closed={setNoSelected} patternChanged={patternChanged} /> : null}


                    <Box>
                        <br />
                        <Heading size={5} className="is-capitalized"> <span className="has-text-grey-light">For </span> {customer.name} </Heading>
                        <ClientMeasurementsInfo className="topMargin10" customer={customer} images={images} hwsUpdated={hwsUpdated} updateNote={noteUpdate} isUpdaingCust={isUpdatingCust} measurements={measurements} customerImagesFetched={setCustImages} customerMeasurementsFetched={setCustMeasurements} imageSearchNeeded={imagesFetchNeeded} measurementSearchNeeded={measuresFetchNeeded} addImagePressed={addImagePressed} addMeasurementPressed={addMeasurementPressed} editPosturesPressed={editPosturePressed} addPatternPressed={addPatternPressed} editPatternPressed={editPatternPressed} imageSelected={imageSelected} measurementIsSelected={measurementIsSelected} />
                    </Box>
                </Modal.Content>
            </Modal>
        )
    }


    return (
        modal()
    )

}

export default ClientInfoModal