import React, { useState, useEffect } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Table, Button, Modal, Box } from 'react-bulma-components/dist';
import { User } from '../../utilities/UserClasses'
import UserCard from '../../Departments/Users/userCard'
import UserModal from './userModal-original'
import { getCurrentLocation, getLocations, getPositions, saveUsers } from '../../utilities/Store';
import cogoToast from 'cogo-toast';
import LoadingAux from '../../containers/LoadingAux';
import ColumnAux from '../../containers/ColumnAux';


const AssignUsersModal = (props) => {

    const [users, setUsers] = useState([])
    const [fetching, setFetching] = useState([])


    useEffect(() => {

        setFetching(true)
        User.getAll((succ, usrs, errMsg) => {
            setFetching(false)
            if (succ) {

                // remove deleted users

                let filteredUsers = usrs.filter((thisUsr) => {
                    return (thisUsr.isDeleted !== true)
                })

                let cl = getCurrentLocation()

                if (cl.isMain !== true) {
                    filteredUsers = filteredUsers.filter((thisUsr) => {
                        // console.log('CL =')
                        // console.log(cl)
                        // console.log('thisUsr')
                        // console.log(thisUsr)
                        return thisUsr.locationId === cl.objectId
                    })
                }

                setUsers(filteredUsers)
            } else {
                cogoToast.error('Could not fetch Users. An error occured.')
                // console.log(errMsg)
            }
        })

    }, [])


    const userSelected = (u) => {
        if (props.userSelected){
            props.userSelected(u)
        }
    }


    // getAllUsers that match the criteria

    const modal = () => {


        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed} >
                <Modal.Content className='is-padingless has-background-light'>

                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>


                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Select User </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>


                            {
                                fetching ? <p className='has-text-grey-light has-text-centered'> Loading...</p> :

                                    <>
                                        {users.length ? <Table className='is-hoverable is-size-7'>
                                            <thead>
                                                <tr>
                                                    <th className='is-narrow'>
                                                        Username
                                                    </th>
                                                    <th>
                                                        Position
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users.map((u) => {
                                                        return <tr key={u.objectId}  >
                                                            <th className=''>
                                                                {u.username}
                                                            </th>
                                                            <th className=''>
                                                                {u.position.name}
                                                            </th>
                                                            <th> <Button className=" is-small is-light" onClick={() => { userSelected(u) }} > Select </Button> </th>

                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                            : <p className='has-text-grey-light has-text-centered'> No matching users </p>}

                                        {users.map((u) => {

                                            return <></>

                                        })}
                                    </>
                            }



                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default AssignUsersModal