import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns, Tag, Table, Dropdown } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import { StyleSelection } from '../../utilities/Classes'
import ProductSelectionModal from './productSelectionModal'
// import { picType } from '../../utilities/enums'

import cogoToast from 'cogo-toast';
import ChoiceModal from '../../components/Cards/ChoiceModal';
import { createNew_CustomProductionStep_Work_WithId } from '../../utilities/helper';

const ProductionStepWorkModal = (props) => {


    


    let [work, setWork] = useState(props.work ?? createNew_CustomProductionStep_Work_WithId())
    let [saving, setSaving] = useState(false)
    let [showChoiceModal, setShowChoiceModal] = useState(false)
    let [editForProducts, setEditForProducts] = useState(false)





    const closed = () => {
        props.closed()
    }

    const saveSelection = () => {

        if (work.name == null || work.name === '') {
            cogoToast.warn('Work must have a name')
            return
        }

        if (props.addingWork){
            if (props.workAdded){
                props.workAdded(work)
            }
        }else{
            if (props.workEdited){
                props.workEdited(work)
            }
        }

    }



    const nameIsChanged = (e) => {
        console.log('Name is changed to : ' + e.target.value)
        let w = { ...work }
        w.name = e.target.value
        setWork(w)
    }


    const forProductsChanged = (e) => {
        console.log('FOR PRODUCTS CHANGED : ' + e.target.value)
        let val = e.target.value
        let w = { ...work }
        let thisVal = val.split('/r/n')
        w.applicableOn = thisVal
        setWork(w)
    }

    // const optionsChanged = (e) => {
    //     console.log('Options CHANGED : ' + e.target.value)
    //     let val = e.target.value
    //     let o = { ...styleSelection }
    //     let thisVal = val.replace(/\n/g, "").split(",")

    //     let fVal = thisVal.filter((opt) => {
    //         return opt !== "" && opt !== " " && opt !== ","
    //     })

    //     o.options = fVal
    //     setStyleSelection(o)
    // }

    const editForProductsPressed = () => {
        setEditForProducts(true)
    }

    const closeModals = () => {
        setShowChoiceModal(false)
        setEditForProducts(false)
    }

    const forProductsSaved = (prods) => {
        console.log('FOR PRODUCTS SAVED')
        let w = { ...work }
        w.applicableOn = prods
        setEditForProducts(false)
        setWork(w)
    }






    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />



                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Name:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='name' defaultValue={work.name ?? ''} placeholder='Name this Work' type='input' className='input field has-text-weight-semibold type65' onChange={e => { nameIsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">FOR PRODUCTS:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <Button className=" is-fullwidth is-small has-background-grey-lighter has-text-grey has-text-weight-bold" onClick={editForProductsPressed}> <FontAwesomeIcon className='' icon={faEdit} /> </Button>
                            <div className='field '>
                                <textarea disabled name='username' defaultValue={work.applicableOn ? work.applicableOn.join(', ') : null} placeholder='Selection is applicable on which products' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { forProductsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>

                {/* <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">OPTIONS:</label>
                        <p className='type45'>Seperate entries by comma and new line (, )</p>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <textarea name='username' defaultValue={styleSelection.options ? styleSelection.options.join(',\n') : null} placeholder='All the options of Selection' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { optionsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns> */}




                    <br />
                    <br />
                    <Button disabled={saving} loading={saving} className='is-fullwidth is-black ' onClick={saveSelection} > Done </Button>
 
                    <br />

            </div>
        )

    }

    const deleteThisSelection = () => {
        if (props.workDeleted){
            props.workDeleted(work)
        }
        // let ss = StyleSelection.copyFrom(styleSelection)
        // setSaving(true)
        // ss.delete((succ, errMsg) => {
        //     if (succ) {
        //         props.selectionDeleted(ss, props.styleSelectionIndex)
        //     } else {
        //         console.log("Could not delete StyleSelection. Error = ", errMsg)
        //         cogoToast.error("Could not delete StyleSelection. Check internet and try again.")
        //     }
        // })
    }


    const deletePressed = () => {
        setShowChoiceModal(true)
    }


    const dropdownValChanged = (val) => {
        if (val === "delete") {
            deletePressed()
        }
    }



    const modal = () => {

        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => closed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    <Dropdown className=" is-small is-arrowless is-pulled-left has-background-white noDropdownIcon" color="light" label='•••' onChange={(val) => { dropdownValChanged(val) }} >
                        <Dropdown.Item value="delete">
                            Delete
                        </Dropdown.Item>
                    </Dropdown>
                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.addingStep ? 'New Production Work' : 'Production Work Details'}  </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    const deleteChoices = [
        {
            key: 'delete',
            title: 'Are you sure you want to delete this work. It will not show in any associated products. This cannot be undone.',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Yes. Delete It.',
            highlight: false
        },
        {
            key: 'cancel',
            title: '',
            needsRemark: false,
            remark: '',
            remarkPlaceholder: '',
            buttonTitle: 'Go Back',
            highlight: false
        },
    ]



    const choiceMade = (choice) => {
        console.log('CHOICE MADE IS')
        console.log(choice)
        closeModals()

        switch (choice.key) {
            case 'delete':
                deleteThisSelection()
                break;
            case 'cancel':
                break;
            default: break;

        }
    }


    return (
        <div>
            {showChoiceModal ? <ChoiceModal choices={deleteChoices} choiceMade={choiceMade} closed={closeModals} /> : null}
            {editForProducts ? <ProductSelectionModal useAllProductSelector={true} selectedProducts={work.applicableOn} selectionSaved={forProductsSaved} closed={closeModals} /> : modal()}
            {modal()}

        </div>

    )

}

export default ProductionStepWorkModal