import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Card, Image, Columns, Table, Dropdown, Tag } from 'react-bulma-components/dist';
import DatePicker from 'react-datepicker'
import { ProductType, productType_allValues, ProductType_options_NonFormik } from '../../ClientInfo/clientInfo';
import ColumnAux from '../../containers/ColumnAux'
import { Customer, Product } from '../../utilities/Classes';
import { createProductId } from '../../utilities/helper';
import { getCurrentUserInfo } from '../../utilities/Store';



const ProductAddition = (props) => {

    let [prodType, setProdType] = useState(productType_allValues()[0])
    let [trialDate, setTrialDate] = useState(null)
    let [deliveryDate, setDeliveryDate] = useState(null)
    let [count, setCount] = useState(1)

    let [isSaving, setIsSaving] = useState(false)



    const typeChanged = (e) => {
        console.log("TYPE CHANGED", e.target.value)
        setProdType(e.target.value)
    }

    const countChanged = (val) => {
        console.log("COUNT IS CHANGED", val)
        setCount(parseInt(val))
    }

    const trialChanged = (val) => {
        setTrialDate(val)
    }

    const deliveryChanged = (val) => {
        setDeliveryDate(val)
    }





    const savePressed = () => {



        if (prodType) {
            setIsSaving(true)


            for (let i = 1; i <= count; i++) {

                

                let np = Product.init()
                np.type = prodType
                np.order = props.order
                np.customer = props.customer ?? props.order.customer
                np.trialDate = trialDate
                np.deliveryDate = deliveryDate
                np.productId = createProductId(props.order.orderNo)

                np.add((succ, errMsg) => {

                    console.log('PRODUCT ADDED')

                    if (succ) {
                        console.log('Product Added')
                        // console.log(np)

                    } else {
                        let message = 'Could not add Product. Check internet and try again.'
                        console.log(message)
                        console.log(errMsg)
                        alert(message)
                        // console.log(errMsg)
                    }
                })



            }

            setTimeout(()=> {
                setIsSaving(false)
                props.productAdded()

            }, 2000 + (600 * count))



        }else{
            alert("Select a Product Type")
        }







    }


    let modal = () => {

        return <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={props.closed} >
            <Modal.Content className='is-padingless has-background-light minHeight600'>



                <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>
                    <div>
                        <Heading key='3' size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > New Product </Heading>
                        <br />
                    </div>
                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                        <div>
                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Product:</label>
                                </Columns.Column>
                                <Columns.Column>

                                    <div className=" ">

                                        <span className="select has-text-weight-semibold type65 ">
                                            {ProductType_options_NonFormik('type', '  ', typeChanged, prodType, false)}
                                        </span>

                                    </div>

                                </Columns.Column>
                            </Columns>

                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Count:</label>
                                </Columns.Column>
                                <Columns.Column>
                                    <div className=" ">

                                        <span className="select has-text-weight-semibold type65 ">




                                            {/* <select name="ProductCount" value={"1" ?? ''} onChange={onCountChange}> */}
                                            <select name="ProductCount" value={count} onChange={(e) => { countChanged(e.target.value) }} >

                                                {[...Array(19).keys()].map((thisVal) => {
                                                    let upVal = thisVal + 1
                                                    return <option key={upVal} value={upVal}>{upVal}</option>
                                                })}

                                            </select>


                                        </span>
                                    </div>
                                </Columns.Column>
                            </Columns>

                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Trial Date:</label>
                                </Columns.Column>
                                <Columns.Column>
                                    <div className="control is-fullwidth is-expanded">
                                        <DatePicker onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='trialDate' placeholderText='mm/dd/yyyy' selected={trialDate} onChange={date => trialChanged(date)} />
                                    </div>
                                </Columns.Column>
                            </Columns>


                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Delivery Date:</label>
                                </Columns.Column>
                                <Columns.Column>
                                    <div className="control is-fullwidth is-expanded">
                                        <DatePicker onFocus={e => e.target.blur()} dateFormat="dd/MMM/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' name='deliveryDate' placeholderText='mm/dd/yyyy' selected={deliveryDate} onChange={date => deliveryChanged(date)} />
                                    </div>
                                </Columns.Column>
                            </Columns>
                            <br />
                            <br />
                            <br />
                            <Button disabled={isSaving} loading={isSaving} className='is-fullwidth is-black' onClick={savePressed} > Save </Button>

                        </div>
                    </ColumnAux>

                </Box>
            </Modal.Content>
        </Modal>

    }

    return modal()

}

export default ProductAddition