import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Button, Columns, Box, Table } from 'react-bulma-components/dist';
import { getCustomQualityChecklist, getTwConfig, saveTwConfig } from '../../utilities/Store';
import {  CustomProductionStep, CustomProductionStepsJSON, CustomQualityListPoint, TWConfig } from '../../utilities/UserClasses';
import cogoToast from 'cogo-toast';
import CustQualityModal from './CustQualityModal'
import CustProductionStepModal from './custProductionStepModal';



const CustomProductionSettings = () => {

    const newProductionSteps = {
        "steps": [
            {
                "order": 1,
                "name": "Some name",
                "id": "",
                "addedBy": "",
                "createdAt": "",
                "updatedAt": "",
                "updatedBy": "",
                "worksTemplate": [
                    {
                        "order": 1,
                        "name": "Some name of work",
                        "id": "",
                        "addedBy": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "updatedBy": "",
                        "applicableOnProducts": ["name1", "name2"]
                    }
                ]
            }
        ]
    }

    const currentSteps = () => {
        let config = getTwConfig()
        let steps = []
        if (config.customProductionStepsJSON) {
            if (config.customProductionStepsJSON.steps) {
                steps = config.customProductionStepsJSON.steps
            }
        }
        if (steps.length) {
            steps = steps.sort((a, b) => a.order - b.order);
        }
        return steps
    }


    const currentQualityList = () => {
        let qlist = getCustomQualityChecklist() ?? []
        if (qlist.length) {
            qlist = qlist.sort((a, b) => a.order - b.order);
        }
        return qlist
    }

    const [steps, setSteps] = useState(currentSteps())
    const [addingStep, setAddingStep] = useState(false)
    const [selectedStep, setSelectedStep] = useState(null)
    const [showStepModal, setShowStepModal] = useState(false)

    const [stepsChanged, setStepsChanged] = useState(false)




    const [qualityList, setQualityList] = useState(currentQualityList())
    const [addingQualityPoint, setAddingQualityList] = useState(false)
    const [selectedQualityPoint, setSelectedQualityPoint] = useState(null)
    const [showQualityModal, setShowQualityModal] = useState(false)
    const [qualityChanged, setQualityChanged] = useState(false)


    const [saving, setSaving] = useState(false)



    const addQPointPressed = () => {
        setAddingQualityList(true)
        setShowQualityModal(true)
    }

    const addStepPressed = () => {
        setAddingStep(true)
        setShowStepModal(true)

    }

    const closeModals = () => {
        setAddingStep(false)
        setSelectedStep(null)
        setShowStepModal(false)

        setShowQualityModal(false)
        setSelectedQualityPoint(null)
    }


    const pointAdded = (qtPoint) => {
        let qt = { ...qtPoint }
        if (!qt.order) {
            qt.order = qualityList.length + 1
        }

        // console.log("NEWLY ADDED STEP IS ", st )
        let currentList = [...qualityList]

        currentList.push(qt)
        currentList.sort((a, b) => a.order - b.order);
        setQualityList(currentList)
        setQualityChanged(true)
        closeModals()

    }

    const pointEdited = (qPoint) => {
        let cQ = [...qualityList]

        const index = cQ.findIndex((s) => {
            return s.id === qPoint.id
        })


        if (index != null) {
            let thisQ = {...qPoint}
            cQ[index] = thisQ
            setQualityList(cQ)
            setQualityChanged(true)
            closeModals()
        }
    }

    const stepAdded = (step) => {
        let st = { ...step }
        if (!st.order) {
            st.order = steps.length + 1
        }

        // console.log("NEWLY ADDED STEP IS ", st )
        let currentSteps = [...steps]

        currentSteps.push(st)
        currentSteps.sort((a, b) => a.order - b.order);
        setSteps(currentSteps)
        setStepsChanged(true)
        closeModals()
    }

    const stepEdited = (step) => {





        let cSteps = [...steps]

        const index = cSteps.findIndex((s) => {
            return s.id === step.id
        })


        if (index != null) {
            let thisStep = CustomProductionStep.copyFrom(step)
            cSteps[index] = thisStep
            setSteps(cSteps)
            setStepsChanged(true)
            closeModals()
        }

    }

    const pointDeleted = (pt) => {
        let cSteps = [...qualityList]

        cSteps = cSteps.filter((s) => { return s.id !== pt.id })

        console.log("cSteps are filter ", cSteps)

        setQualityList(cSteps)
        setQualityChanged(true)
        closeModals()
    }

    const stepDeleted = (step) => {


        console.log("Incoming step to delete ", step)


        let cSteps = [...steps]

        cSteps = cSteps.filter((s) => { return s.id !== step.id })

        console.log("cSteps are filter ", cSteps)

        setSteps(cSteps)
        setStepsChanged(true)
        closeModals()


    }


    const stepRow = (st) => {
        return <tr key={st.id} onClick={() => { setSelectedStep(st) }}>
            <th className='is-narrow'>
                {st.order}
            </th>
            <th>
                {st.name}
            </th>
        </tr>
    }




    const saveChanges = () => {

        if (steps) {
            setSaving(true)


            // get latest config
            TWConfig.getConfig((success, conf, errMsg) => {
                console.log('FETCHING CONFIG')

                if (success) {
                    // save Config
                    console.log('SAVING CONFIG')
                    saveTwConfig(conf)

                    let allConfig = TWConfig.copyFrom(conf)


                    console.log("ALL CONFIG IS", allConfig)

                    // make steps changes into it 
                    let newCustomProductionJSON = new CustomProductionStepsJSON()
                    if (allConfig.customProductionStepsJSON) {
                        if (allConfig.customProductionStepsJSON.steps) {
                            newCustomProductionJSON.steps = allConfig.customProductionStepsJSON.steps
                        }
                    }
                    newCustomProductionJSON.steps = steps
                    allConfig.customProductionStepsJSON = newCustomProductionJSON

                    allConfig.customQualityChecklist = qualityList

                    // save it on backend
                    allConfig.update((succ, obj, errMsg) => {
                        if (succ) {
                            // alert("TwConfig updated")

                            // save it in local storage
                            saveTwConfig(obj)

                        } else {
                            alert(errMsg)
                        }
                        setSaving(false)
                        setStepsChanged(false)
                        cogoToast.success("Changes saved")
                    })










                } else {
                    console.log('Could not fetch config. ' + errMsg)
                }
            })


        }



    }


    const qualityComp = () => {

        return <Box> <p> Quality Checklist here</p> </Box>

    }


    const qualityComp1 = () => {



        let qlist = qualityList ?? []

        return <Box className=''  >


            {showStepModal || selectedStep ? <CustProductionStepModal closed={closeModals} addingStep={addingStep} step={selectedStep} stepAdded={stepAdded} stepEdited={stepEdited} stepDeleted={stepDeleted} /> : null}

            {/* {props.addingPicsMeasurementsAllowed ?? true ? <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={product.objectId == null} onClick={addRemarkPressed} > Add </Button> : null} */}
            {/* <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={addingStep} onClick={addStepPressed} > Add </Button> */}

            <br />

            <Heading size={6} subtitle={false} > Quality Checklist </Heading>

            <Table className='is-hoverable is-size-7'>
                <thead>
                    <tr>
                        <th className='is-narrow'>
                            Order
                        </th>
                        <th>
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        qlist.map((q) => {
                            return <tr key={q.id} onClick={() => { setSelectedQualityPoint(q) }}>
                                <th className='is-narrow'>
                                    {q.order}
                                </th>
                                <th>
                                    {q.name}
                                </th>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            <Box className=" is-shadowless is-radiusless has-background-transparent mb-6">
                <Button.Group className="is-pulled-right mb-0">
                    {/* <Button className=" is-small is-text" onClick={() => { }}> Change Step Order </Button> */}
                    <Button className='is-small has-background-grey-lighter is-pulled-right marginBottom10' disabled={addingStep} onClick={addQPointPressed} >+ Add Point </Button>
                </Button.Group>
                <br />
            </Box>
            <Box className=" is-shadowless is-radiusless has-background-transparent mt-4 mb-6">
                {qualityChanged ? <Button className=' is-fullwidth is-black marginBottom10' disabled={saving} loading={saving} onClick={saveChanges} >Save Changes</Button> : null}
            </Box>

        </Box>
    }



    const stepsComp = () => {
        return <Box className=''  >

            {showStepModal || selectedStep ? <CustProductionStepModal closed={closeModals} addingStep={addingStep} step={selectedStep} stepAdded={stepAdded} stepEdited={stepEdited} stepDeleted={stepDeleted} /> : null}
            {showQualityModal || selectedQualityPoint ? <CustQualityModal closed={closeModals} addingQualityPoint={addingQualityPoint} pointIsAdded={pointAdded} pointIsEdited={pointEdited} point={selectedQualityPoint} pointDeleted={pointDeleted}  /> : null}

            {/* {props.addingPicsMeasurementsAllowed ?? true ? <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={product.objectId == null} onClick={addRemarkPressed} > Add </Button> : null} */}
            {/* <Button className='is-small is-rounded is-pulled-right marginBottom10' disabled={addingStep} onClick={addStepPressed} > Add </Button> */}

            <br />

            <Heading size={6} subtitle={false} > Steps </Heading>

            <Table className='is-hoverable is-size-7'>
                <thead>
                    <tr>
                        <th className='is-narrow'>
                            Order
                        </th>
                        <th>
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        steps.map((s) => {
                            return stepRow(s)
                        })
                    }
                </tbody>
            </Table>
            <Box className=" is-shadowless is-radiusless has-background-transparent mb-6">
                <Button.Group className="is-pulled-right mb-0">
                    {/* <Button className=" is-small is-text" onClick={() => { }}> Change Step Order </Button> */}
                    <Button className='is-small has-background-grey-lighter is-pulled-right marginBottom10' disabled={addingStep} onClick={addStepPressed} >+ Add Step </Button>
                </Button.Group>
                <br />
            </Box>
            <Box className=" is-shadowless is-radiusless has-background-transparent mt-4 mb-6">
                {stepsChanged ? <Button className=' is-fullwidth is-black marginBottom10' disabled={saving} loading={saving} onClick={saveChanges} >Save Changes</Button> : null}
            </Box>

        </Box>
    }



    return <>
        {stepsComp()}
        {qualityComp()}
    </>

}

export default CustomProductionSettings